$enable-grid-classes: false;
$enable-cssgrid: true;

:root {
  // Icons colors
  --icon-color: transparent;
  --background-color: transparent;

  // Input colors
  --input-bg-color: #FFFFFF;
  --input-text-color: #002855;

  // Assitant bubble colors
  --assitant-buble-bg-color: #FFFFFF;
  --assistan-buble-text-color: #002855;
  --assistant-name-color: #8b8b8b;
  --input-focus-color: #00866B;

  // User bubble colors
  --user-buble-bg-color: #00866B;
  --user-buble-text-color: #FFFFFF;

  // Dynamic button colors
  --dynamic-button-bg-color: #002855;
  --dynamic-button-text-color: #FFFFFF;
  --dynamic-button-border-color: #FFFFFF;

  // Dynamic button pressed colors
  --dynamic-button-pressed-bg-color: #000000;
  --dynamic-button-pressed-text-color: #FFFFFF;

  // Skip Button
  --skip-button-bg-color: #FFF;

  --font-family: 'Roboto';
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Nunito';
  src: url(./assets/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: 'Oxygen';
  src: url(./assets/fonts/Oxygen-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Raleway';
  src: url(./assets/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: 'Oswald';
  src: url(./assets/fonts/Oswald-Regular.ttf);
}

@font-face {
  font-family: 'Vag Rounded';
  src: url(./assets/fonts/VAG-Rounded-Light.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--background-image) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  overflow: hidden;
  font-family: var(--font-family), sans-serif !important;
}

.ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 350px !important;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 0px #000 !important;
  color: #000000 !important;
}

.toast-warning {
  background-color: #C9C9C9 !important;
}

// Tooltips =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
.btn-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-tooltip:hover>.tooltip-custom {
  transform: scale(1);
}

.tooltip-custom {
  display: block;
  position: absolute;
  color: #dddddd;
  background: #18191c;
  padding: 0.4rem;
  border-radius: 3px;
  max-width: 150px;
  width: max-content;
  font-size: 0.9rem;
  transform: scale(0);
  transition: 0.055s ease-in-out transform;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
}

.tooltip-up-custom {
  bottom: 58px;
}

.tooltip-up-custom::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #18191c;
}

// Tooltips =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// https://codepen.io/sosuke/pen/Pjoqqp
.icon-color {
  filter: invert(9%) sepia(52%) saturate(4998%) hue-rotate(200deg) brightness(96%) contrast(102%);
  width: 36px;
}

.icons-ui {
  width: 36px;
  height: 36px;
  fill: var(--icon-color);
}

.icons-ui-stroke {
  width: 36px;
  height: 36px;
  stroke: var(--icon-color);
}

@media screen and (max-width: 1400px) {
  .icons-ui {
    width: 32px;
    height: 32px;
    fill: var(--icon-color);
  }

  .icons-ui-stroke {
    width: 32px;
    height: 32px;
    stroke: var(--icon-color);
  }
}

@media screen and (max-width: 768px) {
  .icons-ui {
    width: 28px;
    height: 28px;
    fill: var(--icon-color);
  }

  .icons-ui-stroke {
    width: 26px;
    height: 26px;
    stroke: var(--icon-color);
  }
}

@media screen and (max-width: 640px) {
  .icons-ui {
    width: 24px;
    height: 22px;
  }

  .icons-ui-stroke {
    width: 22px;
    height: 22px;
  }
}

// Virtual Keyboard =-=-=-=-=-=-=-=-=-
.keyboard {
  display: block;
  width: 100%;
  padding: 5px 0;
  margin: 1rem 0rem 0rem 0rem;
  background: var(--user-buble-bg-color);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  user-select: none;
  transition: bottom 0.4s;
}

.keyboard__keys {
  text-align: center;
}

.keyboard__key {
  height: 45px;
  width: 6%;
  max-width: 90px;
  margin: 3px;
  border-radius: 4px;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  color: var(--user-buble-text-color);
  font-size: 1.05rem;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.keyboard__key:active {
  background: rgba(255, 255, 255, 0.12);
}

.keyboard__key--wide {
  width: 12%;
}

.keyboard__key--extra-wide {
  width: 36%;
  max-width: 500px;
}

.keyboard__key--activatable::after {
  content: '';
  top: 10px;
  right: 10px;
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

.keyboard__key--active::after {
  background: #08ff00;
}

.keyboard__key--dark {
  background: rgba(0, 0, 0, 0.25);
}

// Virtual Keyboard =-=-=-=-=-=-=-=-=-

// Swal custom classes
.swalWidgetHeight {
  height: 72%;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .swal2-title {
    font-size: 1em !important; /* Reducir tamaño del título */
    padding: .2em 1em 0;
  }

  .swal2-icon {
    width: 2em !important; /* Reducir tamaño del icono */
    height: 2em !important;
    margin: .6em auto .6em;
  }

  .swal2-html-container{
    font-size: 14px !important;
    padding: 1em 1.6em 0 !important;
    overflow: visible !important;
  }
  
  .swal2-icon-content{
    font-size: 2em !important;
  }
}




.ngb-dp-month {
  pointer-events: none;
  width: 100% !important;
}

.ngb-dp-weekday {
  width: 100% !important;
  height: 2rem;
}

.ngb-dp-day {
  width: 100% !important;
  height: 2rem;
}

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/keen-slider/keen-slider.min.css";